<template>
	<div class="buscar-por-cuit">
		<div class="cont-search">
			<div 
			class="bg-withe icon">
				<i class="icon-search"></i>
			</div>
			<b-form-input
			class="input-search"
			v-model="cuit"
			placeholder="Buscar por CUIT"></b-form-input>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			cuit: '',
			client: null,
			modal_title: '',
			afip_data: null,
		}
	}
}
</script>
<style lang="sass">
.buscar-por-cuit
	display: flex 
	flex-direction: row 
	justify-content: flex-end
	.cont-search
		width: 300px
</style>